import { useState } from 'react'
import { TabOptions } from '../TabOptions'
import { Links } from './Links'
import { History } from './History'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { Comments } from './Comments'

export function TabsLeft() {
  const [option, setOption] = useState('links')

  return (
    <div className="flex flex-1 flex-col items-start gap-2">
      <TabOptions
        defaultValue="links"
        ariaLabel="abs-left-options"
        options={['links', 'comments', 'history']}
        setSelectedOption={setOption}
        selectedOption={option}
      />
      <div
        className={twMerge(
          'flex min-h-[400px] w-full flex-col items-start gap-4 rounded  p-[18px] font-mono text-labelLarge lg:h-full',
          'bg-cromai-m3-sys-light-surface-variant text-cromai-m3-sys-light-on-background',
          'dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-on-background'
        )}
      >
        {option === 'links' && <Links />}
        {option === 'comments' && <Comments />}
        {option === 'history' && <History />}
      </div>
    </div>
  )
}
